import {teamsMatch} from "./teamsMatch.js";
import {archiveScore} from "./archiveScore.js";
import {countdown} from "../helpers/countdown.js";
import {toSVG} from "../helpers/toSVG.js";
import {footerLangPosition} from "./footerLangPosition.js";
import {progressBar} from "../helpers/progressBar.js";
import {toggle} from "../toggle/toggle.js";
import {chartStart} from "../charts/chartStart.js";
import {scrollTable} from "../helpers/scrollTable.js";
import {games} from "../games/games.js";

import {items} from "../helpers/items.js";

export const pages = () => {
    // teamsMatch();
    archiveScore();
    countdown();
    footerLangPosition();
    progressBar();
    toggle();
    chartStart();
    scrollTable();
    games();
    toSVG();

    items();
};