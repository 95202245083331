import {rangeFunc} from "./rangeFunc.js";

export const rangeInputFunc = (item) => {

    // Ensure item is not null and has getAttribute method
    if (!item || typeof item.getAttribute !== 'function') return;

    // Retrieve and parse attribute values or use defaults
    const maxAttr = item.getAttribute('max');
    const minAttr = item.getAttribute('min');
    const max = maxAttr !== null ? Number(maxAttr) : Number.MAX_SAFE_INTEGER;
    const min = minAttr !== null ? Number(minAttr) : 0;

    const originalValue = item.value; // Store original value

    // Convert item.value to a number, default to min if empty or NaN
    const val = originalValue === '' ? min : Number(originalValue);

    // Calculate clamped value
    const clampedValue = Math.min(Math.max(val, min), max);

    // Update the item value if it's empty, NaN, or outside the valid range
    if (originalValue === '' || isNaN(val) || clampedValue !== val) {
        item.value = clampedValue;
    }

    const range = item.closest('.bet__range');
    if (!range) return; // Exit if no parent range element found

    const inputRange = range.querySelector('.input-range');
    if (!inputRange) return; // Exit if no inputRange found

    // Update the inputRange value and call rangeFunc if the value has changed
    if (inputRange.value !== item.value) {
        inputRange.value = item.value;
        rangeFunc(inputRange, false);
    }
};

export const rangeInputFuncInit = () => {
    const rangeInputs = document.querySelectorAll('.input-range-value');
    rangeInputs.forEach(rangeInputFunc); // Simplified iteration
};