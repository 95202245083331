export const progressBar = () => {
    const progressBarBlock = document.querySelectorAll('.progress__bar');

    if (!progressBarBlock.length) return false;

    progressBarBlock.forEach(item => {
        const title = item.querySelector('.progress__bar-title span');
        let data = title.getAttribute('data-progressbar');
        const dashes = title.dataset.dashes ?? data;
        const dashesMax = title.dataset.dashesMax ?? 100;
        const progressLine = item.querySelector('.progress__bar-line span');
        const progressDashes = item.querySelector('.progress__bar-dashes');

        if (!data && dashes) data = Math.round((dashes / dashes) * 100); 

        if (progressLine) item.querySelector('.progress__bar-line span').style = `width:${data}%`;
        if (progressDashes) {
            for (let i = 0; i < dashesMax; i++) {
                const spanElement = document.createElement("span");

                if (i <= dashes) spanElement.classList.add("active");
                if (i == dashes) spanElement.classList.add("end");

                progressDashes.appendChild(spanElement);
            }
        }
    });
};