export const toggleScrollHeight = (toggleHidden, update = false) => {
    if (toggleHidden.length) {
        toggleHidden.forEach(elem => {
            if (update && !elem.classList.contains('hide')) {
                let height = elem.scrollHeight;
                let isToUpdateHeightLater = false;
                if (typeof update == 'string') {
                    let elemToCatchHeight = elem.querySelector(update);
                    if (elemToCatchHeight) {
                        height = elemToCatchHeight.scrollHeight;
                        isToUpdateHeightLater = true;
                    }
                }
                // debugger;
                setTimeout(() => elem.style.height = height + 'px', 10);
                if (isToUpdateHeightLater) setTimeout(() => elem.style.height = elem.scrollHeight + 'px', 300);
            }
            else if (elem.classList.contains('hide')) {
                elem.classList.remove('hide')
                elem.style.height = '0px';
                setTimeout(() => elem.style.height = elem.scrollHeight + 'px', 10);
            } else {
                elem.style.height = elem.scrollHeight + 'px';
                setTimeout(() => elem.style.height = '0px', 10);
                setTimeout(() => elem.classList.add('hide'), 500);
            }
        });
    }
};