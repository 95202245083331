export const form = (item) => { //this function fires for ALL fields in ALL forms on a page on event of input in ANY input on a page
    // let bool = [];
    let form = item.closest('form');

    if (!form) {
        form = item.closest('.validation-form-js');
    }

    if (form) {
        const button = form.querySelector('button.necessarily-impacted, .btn.necessarily-impacted');
        const inputs_necessarily = form.querySelectorAll('.input-necessarily');
        const $repeatPassword = $(form).find('input.valid-passrep');
        const $email = $(form).find('input.valid-email');
        const $recaptcha = $(form).find('textarea.g-recaptcha-response');

        if (!button) {
            console.log('button not found', form);
            return;
        }
        
        //check for filled inputs with class 'input-necessarily' and disable button if any of them is empty
        button.disabled = [...inputs_necessarily].some(e => e.value === null || e.value === '');

        //check that password and repeat password are the same
        if ($repeatPassword.length) {
            let $password = $(form).find('input.valid-pass');
            if ($password.length && $password.val() !== $repeatPassword.val()) {
                button.disabled  = true;
            }
        }
        
        //check that email is valid
        if ($email.length) {
            if (!validateEmail($email.val())) {
                button.disabled = true;
            }
        }



        if ($recaptcha.length) {
           let recaptchaResponse = $recaptcha.val();
           if (!recaptchaResponse) {
               button.disabled = true;
           }
        }
        //else console.log('button disable update error', item, form, button, inputs);
        // if (inputs.length) {
        //     inputs.forEach(elem => {
        //         if (!elem.value) bool.push('false');
        //         else bool.push('true');
        //     });

        //     if (bool.indexOf('false') == -1) button.disabled = false;
        //     else button.disabled = true;
        // }
    }
};

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };