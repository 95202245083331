export const chartNewBlock = () => {
    const chartItems = document.querySelectorAll('.chart-item');

    if (!chartItems.length) return false;

    chartItems.forEach(chartItem => {
        const newBlock = document.createElement('canvas');

        newBlock.id = chartItem.getAttribute('data-chart');
        chartItem.textContent = '';
        chartItem.appendChild(newBlock);
    });
};