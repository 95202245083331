import { countTotalAllDivs } from "./countTotal.js";
import { updateButtonVisibility } from "../bet/updateButtonVisibility.js";
import { swiper_update_height} from "../sliders/sliders.js";

export const sortInventoryByRarityAndGame = ($inventory) => {
    let $inventory_inner_wrapper = getInventoryInnerWrapper($inventory);
    let sortByRarity = $inventory_inner_wrapper.find('.js__sort_rarity .toggle-change-title').data('value') || 'Rarity All';
    let sortByGame = $inventory_inner_wrapper.find('.js__sort_game .toggle-change-title').data('value') || 'Game All';

    $inventory_inner_wrapper.find('.pay__item').each((i, item) => {
        let $item = $(item);
        let rarity = $item.find('[data-rarity]').data('rarity');
        let game = $item.find('[data-game]').data('game');

        if ((rarity === sortByRarity || sortByRarity === 'Rarity All') && (game === sortByGame || sortByGame === 'Game All')) {
            $item.show();
        } else {
            $item.hide();
        }

    });
    swiper_update_height();
    countTotalAllDivs();
    $inventory.data('sort_by_rarity', sortByRarity);
    $inventory.data('sort_by_game', sortByGame);
    $inventory.trigger('inventory:sort');
    updateTextDivAndShowMore($inventory);
}

export const sortInventoryByPrice = ($inventory) => {
    let $inventory_inner_wrapper = getInventoryInnerWrapper($inventory);
    let sortByPrice = 0;
    if ($inventory_inner_wrapper.find('.js__sort_price .active').hasClass('sort_items_by_price_js_down')) {
        sortByPrice = -1;
    } else if ($inventory_inner_wrapper.find('.js__sort_price .active').hasClass('sort_items_by_price_js_up')) {
        sortByPrice = 1;
    }

    if (sortByPrice === 0) {
        return;
    }

    var $items = $inventory.children('.pay__item');



    let itemsArray = $items.toArray().sort(function (a, b) {
        var an = $(a).find('[data-price]').data('price'),
            bn = $(b).find('[data-price]').data('price');

        if (an > bn) {
            return 1 * sortByPrice;
        }
        if (an < bn) {
            return -1 * sortByPrice;
        }
        return 0;
    });

    $items.remove();
    $inventory.prepend($(itemsArray));
    swiper_update_height();
    countTotalAllDivs();
    $inventory.data('sort_by_price', sortByPrice);
    $inventory.trigger('inventory:sort');
    updateTextDivAndShowMore($inventory);
}

export const sortInventoryByDiscount = ($inventory) => {
    let $inventory_inner_wrapper = getInventoryInnerWrapper($inventory);
    let sortByDiscount = 0;
    if ($inventory_inner_wrapper.find('.js__sort_discount .active').hasClass('sort_items_by_discount_js_down')) {
        sortByDiscount = -1;
    } else if ($inventory_inner_wrapper.find('.js__sort_discount .active').hasClass('sort_items_by_discount_js_up')) {
        sortByDiscount = 1;
    }

    if (sortByDiscount === 0) {
        return;
    }

    var $items = $inventory.children('.pay__item');



    let itemsArray = $items.toArray().sort(function (a, b) {
        var an = $(a).find('[data-discount]').data('discount'),
            bn = $(b).find('[data-discount]').data('discount');

        if (an > bn) {
            return 1 * sortByDiscount;
        }
        if (an < bn) {
            return -1 * sortByDiscount;
        }
        return 0;
    });

    $items.remove();
    $inventory.prepend($(itemsArray));
    swiper_update_height();
    countTotalAllDivs();
    $inventory.data('sort_by_discount', sortByDiscount);
    $inventory.trigger('inventory:sort');
    updateTextDivAndShowMore($inventory);
}

const minlengthSearch = 4;

export const sortInventoryByName = ($inventory) => {
    let timeOutMs = $inventory.hasClass('inventory_preload') ? 1000 : 0;
    if ($inventory.data('timeOutId')) {
        clearTimeout($inventory.data('timeOutId'));
    }
    let timeOutId = setTimeout(() => {
        let $inventory_inner_wrapper = getInventoryInnerWrapper($inventory);
        let searchText = $inventory_inner_wrapper.find('.js__sort_search input').val();
        if (searchText.length < minlengthSearch && $inventory.hasClass('inventory_preload')) {
            searchText = '';
        }
        $inventory.find('.pay__item').each((i, item) => {
            let $item = $(item);
            if ($item.find('.pay__item-name').text().toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
                $item.show();
            } else {
                $item.hide();
            }

        });
        swiper_update_height();
        countTotalAllDivs();
        $inventory.data('sort_by_name', searchText);
        $inventory.trigger('inventory:sort');
        updateTextDivAndShowMore($inventory);
    }, timeOutMs);
    $inventory.data('timeOutId', timeOutId); 
}

function getInventoryInnerWrapper($inventory) {
    if ($inventory.closest('.tab-item.active').length > 0) {
        return $inventory.closest('.tab-item.active');
    } else {
        return $inventory.closest('.inventory-wrapper');
    }
}

function updateTextDivAndShowMore($inventory) {
    let $textDiv = $inventory.find('.bet-content-text').first();
    if ($textDiv.siblings(':visible').length === 0) {
        $textDiv.addClass('bet-content-text--only-element');
    } else {
        $textDiv.removeClass('bet-content-text--only-element');
    }
    updateButtonVisibility();
}