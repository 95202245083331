import Cookies from 'js-cookie'

export const initCheckboxes = () => {
    $('#total-check-small').on('change', function () {
        $(this).val(this.checked ? 'agree' : 'disagree');
        Cookies.set('cof_change_agree', this.checked, { expires: 365 });
    });
    $('#total-check-any').on('change', function () {
        $(this).val(this.checked ? 'agree' : 'disagree');
        Cookies.set('cof_change_agree_any', this.checked, { expires: 365 });
    });
}