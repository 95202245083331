export const linkScrollFunc = (e, item) => {
    e.preventDefault();

    const targetId = item.getAttribute('href').substring(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
        targetElement.scrollIntoView({
            behavior: 'smooth'
        });
    }
};