export const jsCopyFunc = (elementToCopy, e) => {
    let strToCopy = elementToCopy.getAttribute('js-copy');
    if (!strToCopy) strToCopy = elementToCopy.innerText;

    navigator.clipboard.writeText(strToCopy).then(() => {
        // message({message: 'Скопировано в буфер', type: 'ok'});
        elementToCopy.classList.add('copied');
        setTimeout( () => { elementToCopy.classList.remove('copied') }, 3000);
        console.log('copied');
    }, (err) => {
        // message({message: 'Ошибка копирования в буфер', type: 'error'});
        console.error('Async: Could not copy text: ', err);
    });
};