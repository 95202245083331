export const toggleChangeItemFunc = (e, item) => {
    //e.preventDefault();

    const toggle = item.closest('.toggle__hover');

    if (toggle && !item.classList.contains('active')) {
        let color;
        const list = item.closest('.toggle__hover-list');
        const title = toggle.querySelector('.toggle-change-title');

        if (list) {
            const listItems = list.querySelectorAll('.toggle-change-item');
            if (listItems.length) {
                listItems.forEach(elem => {
                    elem.classList.remove('active');
                });
            }
        }

        color = window.getComputedStyle(item).color;

        /*  if (item.classList.contains('have-color')) color = window.getComputedStyle(item).color;
          else title.removeAttribute('style');*/

        title.innerHTML = item.innerHTML;
        title.style.color = color;
        item.classList.add('active');

        $(title).data('value', $(item).data('value'));
    }
}