import {toFixed} from '../global.js';

export {countTotalAllDivs};

//count total + hide/show empty_placeholder div
function countTotal(wrapper_div) {
    let total = 0;
    let items_count = 0;
    let $inventory_inner_wrapper = wrapper_div.find('.tab-item.active').length > 0 ? wrapper_div.find('.tab-item.active').first() : wrapper_div;
    let $empty_placeholder = $inventory_inner_wrapper.find('.empty_placeholder');
    let $total = wrapper_div.find('.user_items_price');

    let $total_buttonblock = undefined;
    //my-items exchange betcoins
    if (wrapper_div.hasClass('pay-basket')) {
        $total_buttonblock = wrapper_div.parent('.form_for_js_in_div').find('.up_betcoins_sum_js');
    }
    //trades buy
    if (wrapper_div.find('#js__buy_up').length) {
        $total_buttonblock = wrapper_div.closest('form').find('.up_betcoins_sum_js');
    }
    let $items_count = wrapper_div.find('.user_items_count');
    $inventory_inner_wrapper.find('.pay__item:visible').each(function () {
        let item = $(this);
        let price = parseFloat(item.find('.pay__item-sum').data('price')) || 0;
        total += price;
        items_count += 1;
    });
    let betcoins = parseFloat(wrapper_div.find('span[data-betcoin]').text());
    //check if is number
    if (isNaN(betcoins)) {
        betcoins = 0;
    }
    total += betcoins;
    if (total > 0 || items_count > 0) {
        $empty_placeholder.hide();
    } else {
        if ($inventory_inner_wrapper.find('.empty_placeholder_error_load:visible').length == 0 && $inventory_inner_wrapper.find('.load-inventory').length == 0) {
            $empty_placeholder.show();
        }
    }
    total = toFixed(total, 2);
    $total.text(total);
    if ($total_buttonblock) {
        $total_buttonblock.text(total);
    }
    $items_count.text(items_count);
}

function countTotalAllDivs(){
    $('.user_items_count').each(function(){
        countTotal($(this).parents('.bet-content'));
    });
}

$('.swiper-tabs-wrap').on('changeTab', function(){
    countTotalAllDivs();
});