import {new_swiper} from "./sliders.js";
//import Swiper from 'swiper/swiper.js';

export const swiperFilter = (on) => {
    const swiper = document.querySelector('.swiper-filter');

    if (!swiper) return false;

    const s = new Swiper(swiper, {
        slidesPerView: 'auto',
        slideToClickedSlide: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        on
    });

    new_swiper(s);
    return s;
};