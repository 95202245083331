export const initStreamPlayers = () => {
    $('.stream_languages__tabs').on('click', 'span[data-stream_languages][data-src]:not(.active)', function () {
        $(this).addClass('active').siblings().removeClass('active');
        let stream_src = $(this).data('src');
        let $stream_iframe = $('.video iframe');
        if ($stream_iframe.length) {
            $stream_iframe.attr('src', stream_src);
        } else {
            console.error('No stream iframe');
            return;
        }
        //remove placeholder images
        $('.video-play').hide();
        $('.video-item img').hide();
    });

    $('.video-item').on('click', function () {
        let stream_src = $('.video iframe[data-src]').data('src');
        if (!stream_src) {
            console.error('No stream src');
            return;
        }
        let $stream_iframe = $('.video iframe');
        if ($stream_iframe.length) {
            $stream_iframe.attr('src', stream_src);
        } else {
            console.error('No stream iframe');
            return;
        }
        //remove placeholder images
        $('.video-play').hide();
        $('.video-item img').hide();
    });
}