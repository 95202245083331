import {hintWrap} from "./hintWrap.js";

export const paymentFunc = (item) => {
    const val = item.value;
    const parent = item.closest('.input-item');
    const hint = parent.querySelector('.hint');
    const min = item.getAttribute('min');
    const max = item.getAttribute('max');
    const currency = item.getAttribute('currency');

    // if (val <= min) item.value = '';
    if (min !== null && val < +min) {
        item.classList.add('error');
        if (!hint || hint && hint.classList.contains('hint-max')) {
            if (hint) hint.remove();
            if (parent) parent.append(hintWrap(+min, currency, false));
        }
    }
    else if (max !== null && val > +max) {
        item.classList.add('error');
        if (!hint || hint && hint.classList.contains('hint-min')) {
            if (hint) hint.remove();
            if (parent) parent.append(hintWrap(+max, currency));
        }
        // if (parent && !hint) parent.append(hintWrap(max, currency));
    } else {
        item.classList.remove('error');
        if (hint) hint.remove();
    }
};