import {swiperTabsList} from "../sliders/swiperTabsList.js";
import {swiperTabsWrap} from "../sliders/swiperTabsWrap.js";
import {swiperSlideChange} from "../sliders/swiperSlideChange.js";
import {onObject} from "../sliders/onObject.js";
import {swiperOnInitChoose} from "../sliders/swiperOnInitChoose.js";

export const media = () => {
    const on = onObject();
    const mediaLG = window.matchMedia('(max-width: 1199.98px)');
    const mediaMD = window.matchMedia('(max-width: 767.98px)');
    const mediaSM = window.matchMedia('(max-width: 575.98px)');

    let is = {
        mediaLG: false,
        mediaMD: false,
        mediaSM: false,
    };

    const handleTabletChangeLG = (e) => {
        is.mediaLG = e.matches;
        if (!is.mediaLG && is.mediaMD) {
            is.mediaLGevent = e;
            return;
        }
        const mobWrap = document.querySelector('.mob-wrap');
        const blockTransfer = document.querySelectorAll('.block-transfer');
        const headerWrapper = document.querySelector('.header-wrapper');
        const headerMenu = document.querySelector('.header-menu');
        const headerLang = document.querySelector('.header-menu .footer-lang .toggle__hover');

        if (e.matches) {
            if (headerWrapper && headerMenu) headerWrapper.append(headerMenu);
            if (headerLang) headerLang.classList.add('top');
            if (mobWrap && blockTransfer.length) {
                const reversedBlockTransfer = blockTransfer;//[...blockTransfer].reverse();
                reversedBlockTransfer.forEach((item, index) => {
                    const parent = item.closest('.transfer-back');
                    //console.log('here', item, parent);
                    let attributeIndex = parent.getAttribute('data-transfer-index');
                    if (attributeIndex) attributeIndex += ' ' + index;
                    else attributeIndex = index;
                    parent.setAttribute('data-transfer-index', attributeIndex);
                    if (item.classList.contains('block-transfer-reverse')) mobWrap.append(item);
                    else mobWrap.prepend(item);
                });
            }
        } else {
            document.querySelector('.header').classList.remove('open', 'fixed');
            document.querySelector('.header-wrap').classList.remove('up', 'down');
            document.body.classList.remove('over-y-hide');

            if (headerWrapper && headerMenu) {
                const headerLeft = document.querySelector('.header-left');
                if (headerLeft) headerLeft.append(headerMenu);
            }

            if (headerLang) headerLang.classList.remove('top');

            if (mobWrap && blockTransfer.length) {
                const reversedBlockTransfer = [...blockTransfer].reverse();
                const transferBlockToCleanAttr = [];
                reversedBlockTransfer.forEach((item, index) => {
                    const transferBack = document.querySelector(`.transfer-back[data-transfer-index~='${index}']`);
                    //console.log('there', item, index, transferBack);
                    if (transferBack) {
                        transferBlockToCleanAttr.push(transferBack);
                        // transferBack.removeAttribute('data-transfer-index');
                        if (item.classList.contains('block-transfer-reverse')) transferBack.prepend(item);
                        else transferBack.append(item);
                    }
                });
                transferBlockToCleanAttr.forEach( (transferBack) => transferBack.removeAttribute('data-transfer-index') );
            }
        }
    }

    const handleTabletChangeMD = (e) => {
        is.mediaMD = e.matches;
        if (!is.mediaMD && is.mediaSM) {
            is.mediaMDevent = e;
            return;
        }
        const mobWrap = document.querySelector('.mob-wrap');
        const blockProfileInfo = document.querySelector('.block-profile-info');
        const blockProfileBalance = document.querySelector('.block-profile-balance');
        const profileItemStatistics = document.querySelector('.profile__item-statistics');
        const profileItemGraph = document.querySelector('.profile__item-graph');
        const profileItemRight = document.querySelector('.profile__item-right');
        const filterSwiperTabs = document.querySelector('.filter-swiper-tabs');

        if (e.matches) {
            if (filterSwiperTabs) {
                const headerMenu = document.querySelector('.header-menu');
                headerMenu.prepend(filterSwiperTabs);
            }
            if (blockProfileInfo && blockProfileBalance) {
                const profileInfoBalance = blockProfileInfo.querySelector('.profile__info-balance');
                blockProfileBalance.classList.remove('block');
                profileInfoBalance.append(blockProfileBalance);
            }
            if (mobWrap) {
                const swiperMobWrap = mobWrap.closest('.swiper-mob-wrap');

                if (profileItemStatistics) blockProfileInfo.insertAdjacentElement('afterend', profileItemStatistics);
                if (profileItemGraph) profileItemStatistics.insertAdjacentElement('afterend', profileItemGraph);
                if (swiperMobWrap) {
                    const parentElement = swiperMobWrap.parentNode;
                    const mobWrapList = document.createElement('div');

                    swiperMobWrap.classList.add('swiper-tabs-wrap');
                    mobWrapList.classList.add('swiper', 'swiper-tabs-list', 'swiper-mob-list', 'bc-accent');
                    mobWrapList.innerHTML = `<div class="swiper-wrapper"></div>`;
                    parentElement.insertBefore(mobWrapList, swiperMobWrap);
                    parentElement.classList.add('block', 'block-swiper');

                    const swiperMobList = document.querySelector('.swiper-mob-list .swiper-wrapper');

                    mobWrap.classList.add('swiper-wrapper');

                    mobWrap.querySelectorAll('.block').forEach((item, index) => {
                        const title = item.querySelector('.block__item-title');

                        item.classList.add('swiper-slide');
                        title.classList.add('swiper-slide', 'swiper-tabs-list__item');
                        title.setAttribute('data-index-block', index);
                        swiperMobList.append(title);
                    });

                    const tabsList = swiperTabsList(on);
                    const tabsWrap = swiperTabsWrap();

                    if (tabsList && tabsWrap) swiperSlideChange(tabsList, tabsWrap);
                    if (tabsList && tabsWrap) swiperOnInitChoose(tabsList, tabsWrap);
                }
            }
        } else {
            const mobDie = document.querySelector('.mob-die');
            const fixedBlockMob = document.querySelector('.fixed-block-mob');
            const filterGamesWrap = document.querySelector('.filter-games-wrap');

            if (filterSwiperTabs && filterGamesWrap) filterGamesWrap.append(filterSwiperTabs);
            if (mobDie) mobDie.classList.remove('active');
            if (fixedBlockMob) fixedBlockMob.classList.remove('open');

            document.body.classList.remove('over-y-hide');

            if (mobWrap) {
                const swiperMobWrap = mobWrap.closest('.swiper-mob-wrap');

                if (swiperMobWrap) {
                    const swiperMobList = document.querySelector('.swiper-mob-list');

                    swiperMobWrap.classList.remove('swiper-tabs-wrap');
                    mobWrap.classList.remove('swiper-wrapper');
                    mobWrap.querySelectorAll('.block').forEach((item, index) => {
                        const blockTop = item.querySelector('.block__item-top');
                        const title = document.querySelector(`.block__item-title[data-index-block='${index}']`);

                        if (title) {
                            title.classList.remove('swiper-slide', 'swiper-tabs-list__item');
                            blockTop.prepend(title);
                        }

                        item.classList.remove('swiper-slide');
                    });

                    if (swiperMobList) {
                        const parent = swiperMobList.parentNode;
                        const swiperInstance = swiperMobList.swiper;

                        swiperMobList.remove();
                        swiperInstance.destroy(true, true);
                        parent.classList.remove('block', 'block-swiper');
                    }
                    if (swiperMobWrap && swiperMobWrap.classList.contains('swiper-initialized')) {
                        const swiperInstance = swiperMobWrap.swiper;
                        swiperInstance.destroy(true, true);
                    }
                }
                if (profileItemRight) {
                    if (profileItemStatistics) profileItemRight.append(profileItemStatistics);
                    if (profileItemGraph) profileItemRight.append(profileItemGraph);
                }
                if (blockProfileInfo && blockProfileBalance) {
                    blockProfileBalance.classList.add('block');
                    blockProfileInfo.insertAdjacentElement('afterend', blockProfileBalance);
                }
            }
        }

        if (is.mediaLGevent) {
            let event = is.mediaLGevent;
            is.mediaLGevent = null;
            handleTabletChangeLG(event);
        }
    }

    const handleTabletChangeSM = (e) => {
        is.mediaSM = e.matches;
        const mobMinText = document.querySelectorAll('[data-mob-min-text]');

        if (e.matches) {
            if (mobMinText.length) {
                mobMinText.forEach(item => {
                    const data = item.getAttribute('data-mob-min-text').replace(/'/g, '"');
                    const dataObject = JSON.parse(data);

                    item.textContent = dataObject.mob;
                });
            }
        } else {
            if (mobMinText.length) {
                mobMinText.forEach(item => {
                    const data = item.getAttribute('data-mob-min-text').replace(/'/g, '"');
                    const dataObject = JSON.parse(data);

                    item.textContent = dataObject.desk;
                });
            }
        }

        if (is.mediaMDevent) {
            let event = is.mediaMDevent;
            is.mediaMDevent = null;
            handleTabletChangeMD(event);
        }
    }

    mediaLG.addEventListener("change", handleTabletChangeLG);
    handleTabletChangeLG(mediaLG);

    mediaMD.addEventListener("change", handleTabletChangeMD);
    handleTabletChangeMD(mediaMD);

    mediaSM.addEventListener("change", handleTabletChangeSM);
    handleTabletChangeSM(mediaSM);
};