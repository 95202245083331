export function updateBalance(balance) {
    const { balance: balance_main, balance_frozen, balance_steam, balance_steam_frozen, balance_steam_count } = balance;
    $('.js_header_balance_main').text(balance_main);
    if (balance_frozen > 0) {
        $('.header-profile__item .js_header_balance_main_frozen').prop('title', $('.js_header_balance_main_frozen').data('title').replace('{%$user_value_frozen%}', balance_frozen)).show();
        $('.balance__item--frozen-line span.js_header_balance_main_frozen').text(balance_frozen);
    } else {
        $('.header-profile__item .js_header_balance_main_frozen').prop('title', '').hide();
        $('.balance__item--frozen-line span.js_header_balance_main_frozen').text(`0.00`);
    }
    
    $('.js_header_balance_steam').text(balance_steam);
    if (balance_steam_frozen > 0) {
        $('.header-profile__item .js_header_balance_steam_frozen').prop('title', $('.js_header_balance_steam_frozen').data('title').replace('{%$user_value_frozen%}', balance_steam_frozen)).show();
        $('.balance__item--frozen-line span.js_header_balance_steam_frozen').text(balance_steam_frozen);
    } else {
        $('.header-profile__item .js_header_balance_steam_frozen').prop('title', ``).hide();
        $('.balance__item--frozen-line span.js_header_balance_steam_frozen').text(`0.00`);
    }

    $('.js_header_balance_steam_count').text(balance_steam_count);
}