import { displayMessage } from './alert/messageFunc.js';
import { updateBalance } from './helpers/updateBalance.js';

var ws = null; 
var ws_id = "";
var wsAddress = "wss://socket.bets4.info:2346/?user_id="
var ws_connection = null;
var ws_error_num = 0;
var ws_error_limit = 5; 
var ws_async_timeout = null;
var ws_debug = true;

export function wsSubscribe(id) {
    ws = window.WebSocket || window.MozWebSocket;
    if (!ws) {
        displayLog('Sorry, but your browser does not support WebSockets.');
    }
    ws_id = id;
    wsConnect();
}

function wsConnect() {
    if (ws_connection != null)
        return;
    wsAsyncConnect(true);
}

function wsAsyncConnect(immediate = false)
{
    if (ws_async_timeout == null){
        const connectWebSocket = () => {
            displayLog("Connecting to websocket server...");
            ws_error_num++; 
            ws_connection = null; 
            if (ws_error_num > ws_error_limit) { 
                console.log("Connection failed. Please check your internet connection."); 
                return; 
            }
            try {
                ws_connection = new WebSocket(wsAddress+ws_id);
                ws_connection.onopen = function () {
                    displayLog("Connection to WebSocket initialized.");
                    clearTimeout(ws_async_timeout);
                    ws_async_timeout = null;
                    ws_error_num = 0;
                };
                ws_connection.onmessage = function (event) {
                    try { 
                        var json = JSON.parse(event.data); 
                        displayLog(json);
                        if (event.data == "ws:ping") { 
                            ws_connection.send("ws:pong"); 
                        } 
                        if (json.message) {
                            const { value, type, timeout } = json.message;
                            displayMessage(value, type, null, timeout);
                        } 
                        if (json.balance) {
                            updateBalance(json.balance);
                        }
                    } catch (e) {
                        displayLog('This doesn\'t look like a valid JSON: ' + event.data); 
                        return; 
                    }
                };
                ws_connection.onerror = function (event) {
                    displayLog("Connection to WebSocket failed.");
                    ws_error_num++; 
                    ws_connection = null; 
                    wsAsyncConnect();
                };
                ws_connection.onclose = function () {
                    displayLog("Connection failed. Reconnect in 5 seconds...");
                    ws_error_num++; 
                    ws_connection = null; 
                    wsAsyncConnect(); 
                }
            } catch (e) { 
                wsAsyncConnect(); 
            }
        };

        if (immediate) {
            connectWebSocket();
        } else {
            ws_async_timeout = setTimeout(connectWebSocket, 5000);
        }
    }
}

function displayLog(message) {
    if (ws_debug) {
        console.log(message);
    }
}
