import Cookies from 'js-cookie'

$(document).on('click', '#news-js-id-remove', function () {
    var $news_js_div = $(this).parents('#news-js-id');
    var cookie_name = 'news-remove-' + $news_js_div.data('newsid');
    $news_js_div.remove();
    Cookies.set(cookie_name, Date.now(), { expires: 30 });
});




// function to get data from the server returns a promise of json data
export const getServerDataJsonObject = (url, postData) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    const urlencoded = new URLSearchParams();
    urlencoded.append("post-json", postData);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
    };

    return fetch(url, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.error(error));
}



export const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
}

//создание рандом токенов для форм
$('input.token-js').each(function () {
    $(this).val(uuidv4());
});

//округляшка
export const toFixed = (num, fixed) => {
    //console.log(num);
    if (isNaN(num)) {
        num = 0;
    } else {
        num = Number(num);
        //let addMinValueToFix9999Tail = Number(0.000000000000001);
        let addMinValueToFix9999Tail = 0.01;
        for (let i = 0; i < fixed; i++) {
            addMinValueToFix9999Tail /= 10;
        }
        num += addMinValueToFix9999Tail;
    }

    //console.log(num);
    num = parseFloat(num).toFixed(15);
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
};

function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

window.history.replaceState({}, document.title, removeURLParameter(window.location.href, 'error'));
window.history.replaceState({}, document.title, removeURLParameter(window.location.href, 'notification'));

export const throttleFunc = (f, delay = 0, ensure = false) => {
    let lastCall = Number.NEGATIVE_INFINITY;
    let wait;
    let handle;
    return (...args) => {
      wait = lastCall + delay - Date.now();
      clearTimeout(handle);
      if (wait <= 0 || ensure) {
        handle = setTimeout(() => {
          f(...args);
          lastCall = Date.now();
        }, wait);
      }
    };
  }

export function addUrlParameter(key, value) {
    if (!key || !value) {
        return false;
    }
    var url = window.location.href;
    url = removeURLParameter(url, key);
    if (url.indexOf('?') === -1) {
        url += '?';
    } else {
        url += '&';
    }
    url += key + '=' + value;
    window.history.replaceState({}, document.title, url);
    return url;
}

export function getUrlParameter(key) {
    var url = window.location.href;
    if (url.indexOf('?') === -1) {
        return false;
    }
    var urlParameters = url.split('?')[1].split('&');
    for (var i = 0; i < urlParameters.length; i++) {
        var parameter = urlParameters[i].split('=');
        if (parameter[0] === key) {
            return parameter[1];
        }
    }
    return false;
}   