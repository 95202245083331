export const countdown = () => {
    const countdownElements = document.querySelectorAll('.countdown-title');

    if (!countdownElements) return false;
    countdownElements.forEach(countdownElement => {
        function startCountdown() {
            const dataTime = countdownElement.getAttribute('data-time') * 1000;
            const endDateTime = new Date((new Date()).getTime() + dataTime); //end datetime in user's timezone
            const textAfterExpire = countdownElement.getAttribute('text-after-end-countdown') || "Countdown expired!";
            const countdownTextElement = countdownElement.querySelector('.countdown-text');
            const hhmmssElement = $(countdownElement).find('.countdown--hours_and_less');
            const daysElement = $(countdownElement).find('.countdown--days');
            function updateCountdown() {
                const currentDate = new Date();
                const timeDifference = endDateTime - currentDate;

                if (timeDifference > 0) {
                    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                    // countdownElement.innerHTML = `${days} Days <br> ${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;
                    hhmmssElement.text(`${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`);
                    daysElement.text(`${days}`);
                    setTimeout(updateCountdown, 1000);
                } else {
                    $(countdownTextElement).hide();
                    countdownElement.innerHTML = textAfterExpire;
                }
            }

            function formatTime(time) {
                return time < 10 ? `0${time}` : time;
            }

            updateCountdown();
        }

        startCountdown();
    });
}

