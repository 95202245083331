export const jsGameFunc = (e, item) => {
    e.preventDefault();

    const games = item.closest('.games');
    const team_rate_1 = games.querySelector('.team_1_rate');
    const team_rate_2 = games.querySelector('.team_2_rate');
    const team_score = games.querySelector('.team_score');
    const jsGame = games.querySelectorAll('.js-game');
    const titleCrowns = games.querySelectorAll('.games-card-title span:first-child');
    const data = item.getAttribute('data-game').replace(/'/g, '"');
    const dataObject = JSON.parse(data);

    const {team_1_rate, team_2_rate, team_1_score, team_2_score, team_winner} = dataObject;

    jsGame.forEach(elem => {
        if (elem !== item) elem.classList.remove('active');
    });

    item.classList.add('active');
    team_rate_1.textContent = `${team_1_rate}%`;
    team_rate_2.textContent = `${team_2_rate}%`;

    if (titleCrowns.length) titleCrowns.forEach(item => {
        item.removeAttribute('class');
        if (!item.classList.contains('wording')) item.innerHTML = item.textContent;
    });

    if (team_winner) {
        let title;

        if (team_winner == 'team_1') {
            title = games.querySelector('.games-card-left .games-card-title span:first-child');
            title.innerHTML = `<b>${title.textContent}</b>`;
        } else if (team_winner == 'team_2') {
            title = games.querySelector('.games-card-right .games-card-title span:first-child');
            title.innerHTML = `<b>${title.textContent}</b>`;
        }

        title.classList.add('crown-icon');
    }

    if (team_1_score === team_2_score) team_score.textContent = 'VS';
    else team_score.textContent = `${team_1_score}:${team_2_score}`;
};