export const littleThings = () => {
    imgEmojis();
    safariImgPlaceholdersFix();
    // questionHintsFix();
    lightDark(true);
    window.lightDark = lightDark;
    consoleMessage();
};

function imgEmojis() {
    let imgs = document.querySelectorAll('img.placeholder[smile]:not(.smiled), .pseudo-img.placeholder[smile]:not(.smiled)');
    imgs.forEach(i => {
        i.style.setProperty('--svg', `url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20 20"><text x="0" y="15" style="font-size: 15px;">${i.getAttribute('smile')}</text></svg>')`);
        i.classList.add('smiled');
    });
}

function safariImgPlaceholdersFix() {
    // return;
    if (!(window.navigator.userAgent.indexOf("Safari") != -1 && window.navigator.userAgent.indexOf("Chrome") == -1 && window.navigator.userAgent.indexOf("Chromium") == -1)) return;
    // debug_message(window.navigator.userAgent);
    document.querySelectorAll('img').forEach( img => {
        img.addEventListener('load', e => {
            // console.log('error', e);
            console.log('load event', e, img);
            checkHeightWidth(img, onError);
        });
        img.addEventListener('error', e => {
            // console.log('error', e);
            onError(img);
        });
        img.data = img.data;
        // console.log('data', img.data);
        if (img.complete) {
            checkHeightWidth(img, onError);
            // onError(img);
            // console.log('img already loaded', img);
        }
    });

    function checkHeightWidth(img, onError) {
        if ('naturalHeight' in img) {
            if (img.naturalHeight + img.naturalWidth === 0) {
                onError(img);
                return;
            }
        } else if (img.width + img.height == 0) {
            onError(img);
            return;
        }
    }

    function onError(img) {
        if (img.isAlreadyReplaced) return;
        console.log('error loading', img);
        // img.style.background = 'red';
        // return;
        let d = create(null, 'div', /*...img.classList.entries(),*/ [...img.attributes].reduce((result, item) => { result[item.name] = item.value; return result; }, {}), 'pseudo-img');
        // d.innerHTML = img.innerHTML;
        // debugger;
        // d.style.background = 'red';

        img.parentNode.replaceChild(d, img);

        console.log('   replaced element', d);
        img.isAlreadyReplaced = true;
    }
}

// function questionHintsFix() {
//     // let hints = document.querySelectorAll('.question .hint');

//     // let scrollHeight = Math.max(
//     //     document.body.scrollHeight, document.documentElement.scrollHeight,
//     //     document.body.offsetHeight, document.documentElement.offsetHeight,
//     //     document.body.clientHeight, document.documentElement.clientHeight
//     //   );

//     // let scrollWidth = Math.max(
//     //     document.body.scrollWidth, document.documentElement.scrollWidth,
//     //     document.body.offsetWidth, document.documentElement.offsetWidth,
//     //     document.body.clientWidth, document.documentElement.clientWidth
//     //   );

//     // hints.forEach(hint => {
//     //     let rect = hint.getBoundingClientRect();
//     //     console.log('fixing hint', hint, rect, scrollHeight, scrollWidth);
//     // });

//     let questions = document.querySelectorAll('.question');
//     questions.forEach(question => {
//         question.addEventListener('onmouseover', function () )
//         let rect = hint.getBoundingClientRect();
//         console.log('fixing hint', hint, rect, scrollHeight, scrollWidth);
//     });
// }

function debug_message(m, t, isError = false){
	let messageDivId = 'debug_message';
	console.log('message: ', m);
	t = t ?? 10000;
	let messageDiv = document.getElementById(messageDivId);
	if (!messageDiv){	
		messageDiv = create(document.body, 'div');
		messageDiv.id = messageDivId; 
		messageDiv.style = 'position: fixed; bottom: 10px; left: 10px; color: white; background-color: #00000090; z-index: 9999999; margin: 10px;';
	}
	messageDiv.style.backgroundColor = "#00000090";
	messageDiv.innerHTML = m;
	if (m instanceof Object) {
		messageDiv.innerHTML = JSON.stringify(m);
		if(m.isError === true) isError = true;
	}
	if (isError) messageDiv.style.backgroundColor = "#FF000090";
	if (t != -1) setTimeout(()=>messageDiv.remove(), t);
}

function create(target, type, ...classesAndAttributes){
    let el = document.createElement(type);
    classesAndAttributes.forEach((c)=>{
        if (typeof c == 'string') el.classList.add(c);
        else Object.keys(c).forEach( (k) => el.setAttribute(k, c[k]) ); 
    });
    if (target) target.append(el);
    return el;
}

function lightDark(isInit = false) {
    let isLight = localStorage.getItem('light');
    if (!isLight || !(+isLight)) isLight = 0;
    else isLight = +isLight;

    if (!isInit) isLight = (isLight + 1) % 3;

    if (isLight == 1) {
        document.body.classList.add('light');
        document.body.classList.remove('legacy');
    }
    else if (isLight == 2) {
        document.body.classList.add('legacy');
        document.body.classList.remove('light');
    }
    else {
        document.body.classList.remove('light');
        document.body.classList.remove('legacy');
    }
    /*if (!isLight || isLight == 'false') isLight = false;
    else isLight = true;

    if (!isInit) isLight = !isLight;

    if (isLight) document.body.classList.add('light');
    else document.body.classList.remove('light');*/

    if (window.charts) {
        for (let chartInfo of window.charts) {
            chartInfo.chart.options = {...chartInfo.options}; 
            if (chartInfo.data) chartInfo.chart.config.data = {...chartInfo.data}; 
            chartInfo.chart.update();
            // chartInfo.chart.update('none'); //cannot be used when change data color
        }
    }

    if (!isInit) localStorage.setItem('light', isLight);
    else {
        let clicked = 0;
        document.querySelector('.footer-logo').addEventListener('click', () => {
            console.log('clicked', clicked);
            clicked++;
            if (clicked > 5) {
                lightDark();
                clicked = 0;
            }
        })
    }
} 

function consoleMessage() {
    if (!console.image) console.image = (url, text = ' ', ...styles) =>{
        var xhr = new XMLHttpRequest();
        xhr.open('get', url);
        xhr.responseType = 'blob';
        xhr.onload = function(){
            var fr = new FileReader();
    
            fr.onload = function(){
                // const style = `font-size: 300px; background-image: url("${this.result}"); background-size: contain; background-repeat: no-repeat;`;
                const backgroundImage = [
                    `background-image: url("${this.result}")`,
                    "background-size: contain",
                    "background-color: black",
                    "background-repeat: no-repeat",
                    "color: white",
                    "padding: 100px",
                    text != '' ? "padding-top: 200px" : '',
                    "padding-bottom: 20px",
                    "font-weight: bold",
                    "font-size: 25px",
                    ].join(" ;");
                console.log(`%c${text}`, backgroundImage, ...styles);
            };
            fr.readAsDataURL(xhr.response); // async call
        };
        xhr.send();
    }

    // console.image('/template/images/svg/logo.svg', `Bets4.pro Beta`);
    console.image('/template/images/svg/logo.svg', 
        `Bets4.pro Beta %cNever paste and run code that you don't understand %cTo test light version run 'window.lightDark()'`, 
        "color: white; font-size: 25px; background-color: red; padding: 20px;",
        "color: white; font-size: 14px; background-color: black; padding: 20px;");

    // console.log(
    //     "%cNever paste and run code that you don't understand %cTo test light version run 'window.lightDark()'",
    //     "color: white; font-size: 35px; background-color: red; padding: 20px;",
    //     "color: white; font-size: 14px; background-color: black; padding: 20px;"
    // );

    // console.log(
    //     "%cTo test Light version run 'window.LightDark()'",
    //     "color: white; font-size: 14px; background-color: black; padding: 20px;"
    // );
}