export const betItemHoverFunc = (item, event) => {
    const dataBet = item.getAttribute('data-bet');
    const itemBetAll = document.querySelectorAll(`[data-bet="${dataBet}"]`);

    if (itemBetAll.length) {
        itemBetAll.forEach(elem => {
            if (elem != item) {
                if (event == 'mouseover') {
                    item.classList.add('hover');
                    elem.classList.add('hover');
                }
                if (event == 'mouseout') {
                    item.classList.remove('hover');
                    elem.classList.remove('hover');
                }
                if (!item.classList.contains('bet-item-img') && !elem.classList.contains('bet-item-img')) {
                    item.classList.remove('hover');
                    elem.classList.remove('hover');
                }
            }
        });
    }
};