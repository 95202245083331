export const barMenu = (item) => {
    const header = item.closest('header');
    const headerWrap = header.querySelector('.header-wrap');

    document.body.classList.add('over-y-hide');
    header.classList.add('open', 'show');

    if (header.classList.contains('open')) {
        setTimeout(() => {
            headerWrap.classList.add('down');
            header.classList.toggle('fixed');
        }, 100);
    } else headerWrap.classList.remove('down');

    if (header.classList.contains('fixed')) {
        headerWrap.classList.add('up');
        setTimeout(() => {
            headerWrap.classList.remove('up', 'down');
            document.body.classList.remove('over-y-hide');
            header.classList.remove('open');
        }, 100);
        setTimeout(() => header.classList.remove('show'), 400);
    }
}