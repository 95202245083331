export const items = () => {
        //only items not handled before and not on auction page
        return;
        let items = document.querySelectorAll('.pay__item:not(.setting):not(.pay__item--no-hover):not(.js-alert):not(.pay__item--hover-handled)');
        items.forEach(item => {
            item.classList.add('pay__item--hover-handled');
            item.addEventListener('mouseenter', onMouseenter);
        

            function onMouseenter(e) {
                console.log('enter', item);
                
                //array of timeuts to clear them on end
                let timeouts = [];

                let position = item.getBoundingClientRect();
                let showMore = item.closest('.show-more-block');
                //if item is only partially visible in collapsed block return 
                if (showMore && showMore.getBoundingClientRect().bottom < position.bottom) return;
                
                //creation of hovered item
                document.querySelector('.hovered_pay__item-wrapper')?.remove();
                let hovered = document.createElement('div');
                hovered.classList.add('hovered_pay__item-wrapper');
                hovered.append(item.cloneNode(true));
                //name repositioning in tree to grow downwards
                let nameWrapper = document.createElement('div');
                nameWrapper.classList.add('hovered_pay__name-wrapper');
                nameWrapper.append(hovered.querySelector('.pay__item-name'));
                hovered.append(nameWrapper);
                //adding message
                if (item.dataset.itemError || item.dataset.itemMessage) {
                    let messageWrapper = document.createElement('div');
                    messageWrapper.classList.add('hovered_pay__item-message');
                    hovered.append(messageWrapper);
                    if (item.dataset.itemError) messageWrapper.innerHTML = '<span class="hovered_pay__item-message-error">Error: </span>' + item.dataset.itemError;
                    else messageWrapper.innerHTML = item.dataset.itemMessage;
                }

                //initial positioning and sizing (just as original item)
                hovered.style.width = position.width + 'px';
                hovered.style.left = position.x + 'px';
                hovered.style.top = position.y + 'px';

                //display
                document.body.append(hovered);
                hovered.addEventListener('mouseleave', end);
                hovered.addEventListener('click', click);
                document.addEventListener('scroll', end);
                
                //1st animation frame to make sure it is shown in init size
                window.requestAnimationFrame( () => {
                    //2nd animation frame to start grow animation
                    window.requestAnimationFrame(() => {
                        hovered.classList.add('hovered_pay__item-wrapper-grow');
                        //save this timeout to clear it if end function is called earlier
                        timeouts.push(setTimeout(() => hovered?.classList.add('hovered_pay__item-wrapper-growed'), 350));
                        let change = 20;
                        hovered.style.width = position.width + change + 'px';
                        hovered.style.left = position.x - change / 2 + 'px';
                        hovered.style.top = position.y - change / 1.81 / 2 + 'px';
                    });
                });

                function end(e) {
                    console.log('leave', e, item, hovered);
                    if (e.type == 'mouseleave') {
                        //reverse animation start on mouseleave
                        timeouts.forEach(t => clearTimeout(t));
                        timeouts = [];
                        hovered.classList.remove('hovered_pay__item-wrapper-growed');
                        hovered.classList.remove('hovered_pay__item-wrapper-grow');
                        hovered.style.width = position.width + 'px';
                        hovered.style.left = position.x + 'px';
                        hovered.style.top = position.y + 'px';
                        //we should save this timeout so can clear it if scroll happens before animation ends
                        timeouts.push(setTimeout(() => {
                            hovered?.remove();
                            timeouts.forEach(t => clearTimeout(t));
                            timeouts = [];
                            document.removeEventListener('scroll', end);
                        }, 350));
                    }
                    else {
                        //immediate item remove on scroll
                        hovered?.remove(); 
                        timeouts.forEach(t => clearTimeout(t));
                        timeouts = [];
                        document.removeEventListener('scroll', end);
                    }
                }
                function click(e) {
                    console.log('click', e, item, hovered);
                    item.click();
                    end(e);
                }
            }
        });
}

export const onItemHover = function onMouseenter(e, item) {
                if (item.classList.contains('setting') || item.classList.contains('pay__item--no-hover')
                || item.classList.contains('js-alert') || item.classList.contains('pay__item--hover-handled')
                || item.closest('.hovered_pay__item-wrapper')) return;
                // console.log('enter', item);
                
                //array of timeuts to clear them on end
                let timeouts = [];

                let position = item.getBoundingClientRect();
                let showMore = item.closest('.show-more-block');
                //if item is only partially visible in collapsed block return 
                if (showMore && showMore.getBoundingClientRect().bottom < position.bottom) return;
                
                //creation of hovered item
                document.querySelector('.hovered_pay__item-wrapper')?.remove();
                let hovered = document.createElement('div');
                hovered.classList.add('hovered_pay__item-wrapper');
                hovered.append(item.cloneNode(true));
                //name repositioning in tree to grow downwards
                let nameWrapper = document.createElement('div');
                nameWrapper.classList.add('hovered_pay__name-wrapper');
                nameWrapper.append(hovered.querySelector('.pay__item-name'));
                hovered.append(nameWrapper);
                //adding message
                if (item.dataset.itemError || item.dataset.itemMessage || item.dataset.itemWarning) {
                    let messageWrapper = document.createElement('div');
                    messageWrapper.classList.add('hovered_pay__item-message');
                    hovered.append(messageWrapper);
                    if (item.dataset.itemError) messageWrapper.innerHTML = '<span class="hovered_pay__item-message-error">Error: </span>' + item.dataset.itemError;
                    else if (item.dataset.itemWarning) messageWrapper.innerHTML = '<span class="hovered_pay__item-message-warning">Warning: </span>' + item.dataset.itemWarning;
                    else messageWrapper.innerHTML = item.dataset.itemMessage;
                }

                //initial positioning and sizing (just as original item)
                hovered.style.width = position.width + 'px';
                hovered.style.left = position.x + 'px';
                hovered.style.top = position.y + 'px';

                //display
                document.body.append(hovered);
                hovered.addEventListener('mouseleave', end);
                hovered.addEventListener('click', click);
                document.addEventListener('scroll', end);
                let scrollableParent = item.closest('.item-hover-scroll-to-listen, .last');
                if (scrollableParent) scrollableParent.addEventListener('scroll', end);

                //1st animation frame to make sure it is shown in init size
                window.requestAnimationFrame( () => {
                    //2nd animation frame to start grow animation
                    window.requestAnimationFrame(() => {
                        hovered.classList.add('hovered_pay__item-wrapper-grow');
                        //save this timeout to clear it if end function is called earlier
                        timeouts.push(setTimeout(() => hovered?.classList.add('hovered_pay__item-wrapper-growed'), 350));
                        let change = 20;
                        hovered.style.width = position.width + change + 'px';
                        hovered.style.left = position.x - change / 2 + 'px';
                        hovered.style.top = position.y - change / 1.81 / 2 + 'px';
                    });
                });

                function end(e) {
                    // console.log('leave', e, item, hovered);
                    if (e.type == 'mouseleave') {
                        //reverse animation start on mouseleave
                        timeouts.forEach(t => clearTimeout(t));
                        timeouts = [];
                        hovered.classList.remove('hovered_pay__item-wrapper-growed');
                        hovered.classList.remove('hovered_pay__item-wrapper-grow');
                        hovered.style.width = position.width + 'px';
                        hovered.style.left = position.x + 'px';
                        hovered.style.top = position.y + 'px';
                        //we should save this timeout so can clear it if scroll happens before animation ends
                        timeouts.push(setTimeout(() => {
                            hovered?.remove();
                            timeouts.forEach(t => clearTimeout(t));
                            timeouts = [];
                            document.removeEventListener('scroll', end);
                            if (scrollableParent) scrollableParent.removeEventListener('scroll', end);
                        }, 350));
                    }
                    else {
                        //immediate item remove on scroll
                        hovered?.remove(); 
                        timeouts.forEach(t => clearTimeout(t));
                        timeouts = [];
                        document.removeEventListener('scroll', end);
                        if (scrollableParent) scrollableParent.removeEventListener('scroll', end);
                    }
                }
                function click(e) {
                    // console.log('click', e, item, hovered);
                    item.click();
                    end(e);
                }
            }