import {setSlideState} from "./setSlideState.js";

export const onObject = () => {
    return {
        init: function (item) {
            if (Math.floor(this.virtualSize) > Math.floor(this.width)) item.el.setAttribute('data-position', 'start');
            else item.el.setAttribute('data-position', 'hide');
        },
        slideChange: function (item) {
            setSlideState(this, item);
        },
        // touchMove: function (item) {
        //     setSlideState(this, item);
        // },
        transitionEnd: function (item) {
            setSlideState(this, item);
        }
    };
};