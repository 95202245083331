import toastr from 'toastr';

let debug = true;

toastr.options = {
  "closeButton": true,
  "debug": debug,
  "newestOnTop": false,
  "progressBar": true,
  "positionClass": "toast-bottom-center",
  "preventDuplicates": true,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "10000",
  "extendedTimeOut": "0",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut",
  "tapToDismiss": false,
  "disableTimeOut": true,
  "closeOnHover": false
}

/**
 * Displays a toast message with customizable type, title, and timeout.
 * @param {string} message - The message to display in the toast.
 * @param {('success'|'info'|'warning'|'error')} [toast_type='info'] - The type of toast to display.
 * @param {string} [toast_title=''] - The title of the toast.
 * @param {number} [timeOut=10000] - The time in milliseconds before the toast automatically closes.
 */
export function displayMessage(message, toast_type, toast_title, timeOut = 10) {
  timeOut = timeOut * 1000;
  // Validate and set default toast type
  if (!['success', 'info', 'warning', 'error'].includes(toast_type)) {
    toast_type = 'info';
  }

  // Set default title if not provided
  toast_title = toast_title || '';

  // Create a copy of the default options
  const options = { ...toastr.options, timeOut };

  // Display the toast with custom options
  toastr[toast_type](message, toast_title, options);
}

/**
 * Checks for an inline script with a message JSON and displays it if found.
 */
export function checkAndDisplayInlineMessage() {
  try {
    // Find the script tag with id 'inline-message'
    const scriptElement = document.getElementById('inline-message');

    if (scriptElement) {
      // Parse the JSON content of the script tag
      const messageData = JSON.parse(scriptElement.textContent);

      // Check if the parsed data has the required properties
      if (messageData && messageData.message) {
        displayMessage(
          messageData.message,
          messageData.type || 'info',
          messageData.title || '',
          (messageData.timeout || 10) 
        );

        // Remove the script tag after displaying the message
        scriptElement.remove();
      }
    }
  } catch (error) {
    console.error('Error parsing or displaying inline message:', error);
  }
}

// Call the function when the DOM is fully loaded
if (typeof document !== 'undefined') {
  document.addEventListener('DOMContentLoaded', checkAndDisplayInlineMessage);
}