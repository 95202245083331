import {toggleScrollHeight} from "../toggle/toggleScrollHeight.js";

export const moreBetFunc = (e, item) => {
    e.preventDefault();

    const betItem = item.closest('.teams-match').querySelectorAll('.bet-items-hidden');
    const text = item.querySelector('.btn-title');
    let dataText = text.innerHTML;

    if (text) {
        dataText = text.getAttribute('data-text');
        text.setAttribute('data-text', text.innerHTML);
        text.innerHTML = dataText;
    }

    item.classList.toggle('active');

    toggleScrollHeight(betItem);
}