import {toggleScrollHeight} from "./toggleScrollHeight.js";

export const toggleTableFunc = (e, item) => {
    if ($(e.target).closest('button[type="submit"]').length || $(e.target).closest('.dataTable a').length || $(e.target).closest('.js-copy').length || $(e.target).closest('.copied').length) {
        return true;
    }
    e.preventDefault();
    //console.log('here is toggle handle');
    if (item.classList.contains('js-toggle--appeared-tr')) return; //if this is click on "new table" appeared row

    const swiperTabsWrap = item.closest('.swiper-tabs-wrap');
    const dataToggle = item.getAttribute('data-toggle');
    const dataToggleAll = document.querySelectorAll(`[data-toggle='${dataToggle}']`);

    dataToggleAll.forEach(elem => {
        rowFixNewTable(elem);

        elem.classList.toggle('active');
        toggleScrollHeight(elem.querySelectorAll('.toggle-hidden'));
    });

    if (swiperTabsWrap) {
        const swiperInstance = swiperTabsWrap.swiper;
        setTimeout(() => swiperInstance.updateAutoHeight(), 100);
    }

    function rowFixNewTable(tr) {
        if (!tr.classList.contains('js-toggle--appeared-tr')) { //if this is not a "new table" appearing row
            // console.log('rowFixNewTable return', tr);
            return;
        }
        if (!tr.classList.contains('hide')) { //if this is close event, not open
            // console.log('rowFixNewTable hide return', tr);
            return;
        }
        // console.log('rowFixNewTable', tr);
        let td = tr.querySelector('td');
        let div = td.querySelector('div');
        tr.classList.remove('toggle-hidden');
        tr.classList.remove('hide');

        td.classList.remove('js-toggle--appeared-tr');
        td.classList.remove('js-toggle-tr');
        td.classList.remove('toggle-tr-hide');
        td.classList.remove('dark');
        td.classList.remove('light');
        td.classList.add('hide');

        div.classList.add('hide');
    }
};