import {findLongestElement} from "./findLongestElement.js";

export const toggle = () => {
    const toggleAll = document.querySelector('.toggle-all');
    const toggleHover = document.querySelectorAll('.toggle__hover-filter');
    // if (toggleAll) toggleAll.click();

    if (toggleHover.length) {
        toggleHover.forEach(item => {
            const toggleChangeItem = item.querySelectorAll('.toggle-change-item');
            const toggleChangeTitle = item.querySelector('.toggle-change-title');

            if (toggleChangeItem?.length && toggleChangeTitle?.length) {
                const width = findLongestElement(toggleChangeItem);
                toggleChangeTitle.style.width = width + 'px';
            }
        });
    }
};