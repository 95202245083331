export const tabItemFunc = (e, item) => {
    // e.preventDefault();
    const tabBlock = item.closest('.tab-block');
    const data = item.getAttribute('href') ?? item.dataset.tab;
    const tabTitleAll = tabBlock.querySelectorAll(`.js-tab:not([data-tab="${data}"])`);
    const tabItemAll = tabBlock.querySelectorAll(`.tab-item:not([data-tab="${data}"])`);
    // const tabItems = tabBlock.querySelectorAll(`.tab-item[data-tab="${data}"]`);
    const tabTitles = tabBlock.querySelectorAll(`.js-tab[data-tab="${data}"], .js-tab[href="${data}"]`);
    const swiperTabsWrap = item.closest('.swiper-tabs-wrap');
    const swiperTabsWrapItem = item.closest('.swiper-tabs-wrap__item.open-show');
    
    tabItemAll.forEach(elem => {
        // if (elem !== tabItem) 
        elem.classList.remove('active');
    });

    tabTitleAll.forEach(elem => {
        // if (elem !== item) 
        elem.classList.remove('active');
    });

    // if (tabItem) 
    // tabItems.forEach( tabItem => tabItem.classList.add('active'));
    tabTitles.forEach( tabTitle => tabTitle.classList.add('active'));
    item.classList.add('active');
    
    if (swiperTabsWrap) {
        const swiperInstance = swiperTabsWrap.swiper;
        setTimeout(() => swiperInstance.updateAutoHeight(), 50);
    }

    if (swiperTabsWrapItem) swiperTabsWrapItem.classList.remove('open-show');
};