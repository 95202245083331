export const copyFunc = (e, item) => {
    e.preventDefault();

    const copyBlock = item.closest('.block-copy');
    const copyText = copyBlock.querySelector('input');

    const area = document.createElement('textarea');

    document.body.appendChild(area);
    area.value = copyText.value;
    area.select();
    document.execCommand("copy");
    // alert('the text has been copied');
    document.body.removeChild(area);
};