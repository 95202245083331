export const archiveScore = () => {
    const jsArchiveScore = document.querySelectorAll('.js-archive-score');

    if (!jsArchiveScore.length) return false;

    jsArchiveScore.forEach(item => {
        const data = item.getAttribute('data-score');
        const span = document.createElement('span');

        span.innerHTML = `
            <svg viewBox="0 0 36 36" class="icon winrate">
                <path class="circle-bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                <path class="circle" stroke-dasharray="${data}, 100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
            </svg>`;
        item.appendChild(span);
    });
};