// import {chartMonthly} from "./chartMonthly.js";
// import {chartWinrate} from "./chartWinrate.js";
// import {chartDaily} from "./chartDaily.js";
import {chartNewBlock} from "./chartNewBlock.js";

export const charts = (e, item) => {
    if (!item) return false;

    if (e) e.preventDefault();

    const attr = item.getAttribute('data-chart');

    chartNewBlock();

    if (attr === 'monthly')
        {
            import('./chartMonthly.js').then(module => {
                module.chartMonthly();
            }).catch(err => {
                    console.log('chartMonthly load error',err);
            });
        }
    if (attr === 'winrate')
        {
            import('./chartWinrate.js').then(module => {
                module.chartWinrate();
            }).catch(err => {
                    console.log('chartWinrate load error',err);
            });
        }
    if (attr === 'daily')
        {
            import('./chartDaily.js').then(module => {
                module.chartDaily();
            }).catch(err => {
                    console.log('chartDaily load error',err);
            });
        }
}