import {titleChangeFunc} from "./titleChangeFunc.js";
import {swiperAchievement} from "../sliders/swiperAchievement.js";
import {onObject} from "../sliders/onObject.js";
import {toggleScrollHeight} from "./toggleScrollHeight.js";

export const toggleFunc = (e, item) => {
    if ($(e.target).closest('button[type="submit"]').length || $(e.target).closest('.dataTable a').length || $(e.target).closest('.js-copy').length || $(e.target).closest('.copied').length) {
        return true;
    }
    e.preventDefault();

    const toggleWrapAll = document.querySelector('.toggle-wrap-all');
    const swiperTabsWrap = item.closest('.swiper-tabs-wrap');
    const toggleWrap = item.closest('.toggle-wrap');
    const title = item.querySelector('.toggle-title');

    titleChangeFunc(title);

    if (toggleWrap) {
        const dataToggle = item.getAttribute('data-toggle');
        const toggleHidden = toggleWrap.querySelectorAll('.toggle-hidden');

        if (dataToggle) {
            const hideToggle = toggleWrap.querySelectorAll(`.toggle-hidden[data-toggle="${dataToggle}"]`);
            // const elemToggle = toggleWrap.querySelectorAll(`[data-toggle]`);
            const elemToggle = toggleWrap.querySelectorAll(`.toggle-hidden`);
            const elemToggleButtons = toggleWrap.querySelectorAll(`.toggle`);

            if (elemToggle.length) {
                elemToggle.forEach(elem => {
                    if (elem != item && dataToggle != elem.getAttribute('data-toggle')) {
                        // elem.classList.remove('active');
                        elem.style.height = elem.scrollHeight + 'px';
                        setTimeout(() => elem.style.height = '0px', 10);
                        setTimeout(() => elem.classList.add('hide'), 500);
                    }
                });
            }

            if (elemToggleButtons.length) {
                elemToggleButtons.forEach(elem => {
                    if (elem != item && dataToggle != elem.getAttribute('data-toggle')) {
                        elem.classList.remove('active');
                    }
                });
            }

            toggleScrollHeight(hideToggle);
            item.classList.toggle('active');
        } else {
            toggleScrollHeight(toggleHidden);

            toggleWrap.classList.toggle('active');
            toggleWrap.querySelector('.toggle').classList.toggle('active');

            if (toggleWrapAll) {
                const wrapAll = document.querySelectorAll('.toggle-wrap');
                const wrapActive = document.querySelectorAll('.toggle-wrap.active');
                const wrapToggleAll = toggleWrapAll.querySelector('.toggle-all');
                const wrapToggleTitle = wrapToggleAll.querySelector('.toggle-title');

                if (wrapAll.length === wrapActive.length) {
                    wrapToggleAll.classList.add('active');
                    titleChangeFunc(wrapToggleTitle);
                }
                if (wrapActive.length === 0) {
                    wrapToggleAll.classList.remove('active');
                    titleChangeFunc(wrapToggleTitle);
                }
            }
        }
    } else {
        const toggleWrapAll = item.closest('.toggle-wrap-all');
        if (toggleWrapAll) {
            toggleWrapAll.querySelector('.toggle-all').classList.toggle('active');
            const child = toggleWrapAll.querySelectorAll('.toggle-wrap');

            if (child.length) child.forEach(elem => {
                const toggleHidden = elem.querySelector('.toggle-hidden');

                if (toggleWrapAll.querySelector('.toggle-all').classList.contains('active')) {
                    elem.classList.add('active');
                    elem.querySelector('.toggle').classList.add('active');

                    if (toggleHidden) {
                        toggleHidden.classList.remove('hide')
                        toggleHidden.style.height = '0px';
                        setTimeout(() => toggleHidden.style.height = toggleHidden.scrollHeight + 'px', 10);
                    }
                } else {
                    elem.classList.remove('active');
                    elem.querySelector('.toggle').classList.remove('active');

                    if (toggleHidden) {
                        toggleHidden.style.height = toggleHidden.scrollHeight + 'px';
                        setTimeout(() => toggleHidden.style.height = '0px', 10);
                        setTimeout(() => toggleHidden.classList.add('hide'), 500);
                    }
                }
            });
        }
    }

    if (item.closest('.profile__item-achievements')) {
        const profileAchievements = document.querySelector('.profile__item-achievements');
        profileAchievements.classList.toggle('achievements-change');

        let swiper = document.querySelector('.swiper-achievement');

        if (swiper && swiper.classList.contains('swiper-initialized')) {
            const swiperInstance = swiper.swiper;
            if (profileAchievements.classList.contains('achievements-change')) {
                swiperInstance.slideTo(0,0);
                swiperInstance.disable();
            } else {
                swiperInstance.enable();
            }
            // swiperInstance.destroy(true, true);
        } else swiperAchievement(onObject());
    }

    if (swiperTabsWrap) {
        const swiperInstance = swiperTabsWrap.swiper;
        setTimeout(() => swiperInstance.updateAutoHeight(), 100);
    }
};