export const toSVG = () => {
    const getSVG = (file, callback) => {
        const xhr = new XMLHttpRequest();

        xhr.open('GET', file, true);
        xhr.send();
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4) return;
            if (xhr.status !== 200) console.log((xhr.status + ': ' + xhr.statusText));
            else callback.call(this, xhr.responseText);
        };
    };
    const changeIMG = (item, imgURL) => {
        const dataIMG = document.querySelector(`svg[data-img="${imgURL}"]`);
        
        if (dataIMG) {
            item.classList.remove('to-svg');
            const itemClass = item.classList.value;
            // item.outerHTML = dataIMG.outerHTML;
            let clone = dataIMG.cloneNode(true);
            clone.setAttribute('class', itemClass + ' item-svg');
            clone.style.removeProperty('display');
            if (item.style && item.style.display) clone.style.display = item.style.display;
            item.replaceWith(clone);

        } else setTimeout(() => changeIMG(item, imgURL), 500);
    };

    const svgAll = document.querySelectorAll('.to-svg');
    const loadedImages = [];

    if (svgAll.length) {
        svgAll.forEach((item) => {
            const imgURL = item.getAttribute('src');

            if (loadedImages.indexOf(imgURL) !== -1) {
                changeIMG(item, imgURL);
            } else {
                getSVG(imgURL, (data) => {
                    const html = document.createElement("html");

                    html.innerHTML = "<body>" + data + "</body>";
                    item.classList.remove('to-svg');

                    const svg = html.getElementsByTagName("svg")[0];
                    const itemClass = item.classList.value;
                    if (svg) {
                        svg.removeAttribute('xmlns');
                        svg.setAttribute('class', itemClass + ' item-svg');
                        svg.setAttribute('data-img', imgURL);
                        if (item.style && item.style.display) svg.style.display = item.style.display;
                        if (item.parentNode) item.parentNode.replaceChild(svg, item);
                    }
                });
                loadedImages.push(imgURL);
            }
        });
    }
};