import {new_swiper} from "./sliders.js";
//import Swiper from 'swiper/swiper.js';


export const swiperAchievement = (on) => {
    const swiper = document.querySelector('.swiper-achievement');

    if (!swiper) return false;
    

    const s = new Swiper(swiper, {
        slidesPerView: 'auto',
        spaceBetween: 15,
        on
    });
    if (swiper.closest('.profile__item-achievements.active.achievements-change')) {
        s.slideTo(0,0);
        s.disable();
    }
    new_swiper(s);
    return s;
};