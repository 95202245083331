export function scrollTable() {
    const swiper = document.querySelector('.swiper-tabs-wrap');
    const hideColumns = document.querySelectorAll('.table [data-column="hide"]');
    const fixedColumns = document.querySelectorAll('.table [data-column="fixed"]');
    const sliderWrapper = document.querySelector(".section__table");

    if (!sliderWrapper) return false;

    function handleScroll() {
        const scrollLeft = sliderWrapper.scrollLeft;
        const scrollRight = sliderWrapper.scrollWidth - sliderWrapper.clientWidth - scrollLeft;

        if (scrollLeft <= 1) {
            fixedColumns.forEach(column => {
                column.classList.remove("fixed");
                column.style.left = "0";
            });

            if (swiper) {
                const swiperInstance = swiper.swiper;
                swiperInstance.allowTouchMove = true;
            }

            return;
        }

        hideColumns.forEach((hideColumn, index) => {
            if (hideColumn.offsetLeft + hideColumn.offsetWidth <= scrollLeft) {
                const newPosition = Math.max(fixedColumns[index].offsetWidth - scrollLeft + 10, 80);
                fixedColumns[index].style.left = newPosition <= 80 ? scrollLeft - hideColumn.offsetWidth - 5 + "px" : "0";

                if (newPosition <= 80) fixedColumns[index].classList.add("active");
                else fixedColumns[index].classList.remove("active");
            } else {
                fixedColumns[index].classList.remove("active");
                fixedColumns[index].style.left = "0";
            }
        });
    }

    sliderWrapper.addEventListener("scroll", handleScroll);

    return function () {
        sliderWrapper.removeEventListener("scroll", handleScroll);
    };
}