export const sortFunc = (e, item) => {
    e.preventDefault();

    let sortItems = document.querySelectorAll('.sort-item');
    const table = item.closest('.table');
    const filter = item.closest('.bet-content-filters');

    if (table) sortItems = table.querySelectorAll('.sort-item');
    if (filter) sortItems = filter.querySelectorAll('.sort-item');

    let itemToActivate = item;
    if (item.classList.contains('sort') && !item.classList.contains('sort-item')) {
        itemToActivate = item.querySelector('.sort-item:not(.active)');
        if (!itemToActivate) return;
    }

    if (sortItems.length) {
        sortItems.forEach(elem => {
            if (elem != item) elem.classList.remove('active');
        });
    }

    itemToActivate.classList.toggle('active');
};