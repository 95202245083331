export const payFunc = (item) => {
    console.log('payFunc remove???');
    // const parent = item.closest('.tab-item');
    // const payBasket = document.querySelector('.pay-basket .bet-content-wrap');

    // if (!payBasket) return false;

    // const swiperTabsWrap = item.closest('.swiper-tabs-wrap');
    // const payBasketShow = payBasket.querySelector('.bet-content-show');
    // const contentText = payBasket.querySelector('.bet-content-text');

    // if (!item.classList.contains('elem-buy')) {
    //     const tab = parent.getAttribute('data-tab');
    //     const div = document.createElement('div');

    //     item.setAttribute('data-parent', tab);
    //     item.classList.add('elem-buy');

    //     if (!payBasketShow) {
    //         contentText.classList.add('d-none');
    //         div.classList.add('bet-content-show');
    //         div.prepend(item);
    //         payBasket.append(div);
    //     } else payBasketShow.append(item);
    // } else {
    //     const tab = item.getAttribute('data-parent');
    //     const jsTab = document.querySelector(`.js-tab[href="${tab}"]`);
    //     const tabItem = document.querySelector(`.bet-content-wrap .tab-item[data-tab="${tab}"]`);
    //     const betContentShow = tabItem.querySelector(`.bet-content-show`);

    //     item.classList.remove('elem-buy');
    //     item.removeAttribute('data-parent');
    //     betContentShow.append(item);
    //     jsTab.click();

    //     if (!payBasket.querySelectorAll('.js-pay').length) {
    //         contentText.classList.remove('d-none');
    //         payBasketShow.remove();
    //     }
    // }

    // if (swiperTabsWrap) {
    //     const swiperInstance = swiperTabsWrap.swiper;
    //     setTimeout(() => swiperInstance.updateAutoHeight(), 50);
    // }
};