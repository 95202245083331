export const betItemFunc = (item) => {
    if (!item.classList.contains('disabled')  && !item.classList.contains('active')  && !item.classList.contains('bet-item-img')) {
        const betWrap = item.closest('.bet-wrap');
        const teamsMatch = item.closest('.teams-match');
        const dataBet = item.getAttribute('data-bet');

        if (teamsMatch) {
            const betItemAll = teamsMatch.querySelectorAll('.bet-item');

            betItemAll.forEach(elem => {
                if (elem !== item && elem.classList.contains('active')) elem.classList.remove('active');
            });
        } else {
            if (betWrap) {
                const betItemAll = betWrap.querySelectorAll('.bet-item');

                betItemAll.forEach(elem => {
                    if (elem !== item && elem.classList.contains('active')) elem.classList.remove('active');
                });
            }
        }

        if (dataBet) {
            const betItemAll = document.querySelectorAll(`.bet-item-img[data-bet="${dataBet}"]`);

            betItemAll.forEach(elem => {
                if (elem !== item) {
                    if (!elem.classList.contains('active')) elem.classList.add('active');
                    else elem.classList.remove('active');
                }
            });
        }

        item.classList.toggle('active');

        if (!item.classList.contains('active')) {
            const betItem = document.querySelector(`.bet-item-img[data-bet="${dataBet}"]`);
            if (betItem) betItem.classList.remove('active');
        }
    }
};