import {toggleFunc} from "../toggle/toggleFunc.js";
import {toggleTableFunc} from "../toggle/toggleTableFunc.js";
import {tabFunc} from "../tab/tabFunc.js";
import {tabItemFunc} from "../tab/tabItemFunc.js";
import {tabsNavShowFunc} from "../tab/tabsNavShowFunc.js";
import {alertFunc} from "../alert/alertFunc.js";
import {closeFunc} from "../alert/closeFunc.js";
import {jsGameFunc} from "../games/jsGameFunc.js";
import {rangeFunc} from "../range/rangeFunc.js";
import {linkScrollFunc} from "./linkScrollFunc.js";
import {copyFunc} from "./copyFunc.js";
import {btnMoreFunc} from "./btnMoreFunc.js";
import {moreBetFunc} from "../bet/moreBetFunc.js";
import {betItemFunc} from "../bet/betItemFunc.js";
import {charts} from "../charts/charts.js";
import {toggleChangeItemFunc} from "../toggle/toggleChangeItemFunc.js";
import {sortFunc} from "./sortFunc.js";
import {dieFunc} from "./dieFunc.js";
import {barMenu} from "./barMenu.js";
import {paymentFunc} from "../valid/paymentFunc.js";
import {updateButtonVisibility} from "../bet/updateButtonVisibility.js";
import {lastBetScrollChange} from "../bet/lastBetScrollChange.js";
import {betItemHoverFunc} from "../bet/betItemHoverFunc.js";
import {rangeInputFunc} from "../range/rangeInputFunc.js";
import {form} from "../valid/form.js";
import {btnFuncLoad} from "./btnFuncLoad.js";
import {payFunc} from "../bet/payFunc.js";
import {onItemHover} from "./items.js";
import {initStreamPlayers} from "./stream.js";
import {onQuestionHover} from "./questions.js";
import {jsCopyFunc} from "./jsCopy.js";
import {allinCancelFunc} from "../bet/allinCancelFunc.js";


import {sortInventoryByRarityAndGame, sortInventoryByPrice, sortInventoryByName, sortInventoryByDiscount} from "./sortInventory.js";

export const eventDocument = () => {
    document.addEventListener('click', (e) => {
        const target = e.target;
        if ($(target).data('skip-click')) {
            $(target).data('skip-click', false);
            return;
        }
        // target.style.background = 'blue';
        const toggle = target.closest('.toggle');
        const toggleTable = target.closest('.js-toggle-tr');
        const toggleChangeItem = target.closest('.toggle-change-item');
        const tab = target.closest('.js-tab');
        const tabItem = target.closest('.block-tabs__nav + .block-tabs__content .tab-item');
        const betItem = target.closest('.bet-item');
        const close = target.closest('.js-close');
        const btnMore = target.closest('.btn-more');
        const alert = target.closest('.js-alert');
        const linkScroll = target.closest('.js-scroll');
        const jsChart = target.closest('.js-chart');
        const jsGame = target.closest('.js-game');
        const jsMoreBet = target.closest('.js-more-bet');
        const jsCopy = target.closest('.js-copy');
        const tabsNavShow = target.closest('.block-tabs__nav-show');
        const sortItem = target.closest('.sort-item');
        const sort = target.closest('.sort'); //is it needed now??!
        const die = target.closest('.mob-die');
        const jsBar = target.closest('.js-bar');
        // const modal = document.querySelector('.modal.open');
        const btnLoad = target.closest('.btn-load');
        const jsPay = target.closest('.js-pay');
        const jsCopyElement = target.closest('[js-copy]');
        const allinBet = target.closest('.allin');
        const cancelBet = target.closest('.bet-content-cancel');
        const inventory = $(target).closest('.active').length ?
            $(target).closest('.active').find('.inventory').first() :
            $(target).closest('.inventory-wrapper').find('.inventory').first();

        // console.log('we got click!', {target, toggle, toggleTable, toggleTable, toggleChangeItem, tab, tabItem, betItem,
        //     close, btnMore, alert, linkScroll, jsChart, jsGame, jsMoreBet, jsCopy, tabsNavShow, sortItem, sort, die, jsBar,
        //     /*modal,*/ btnLoad, jsPay, jsCopyElement, allinBet, cancelBet, inventory
        // });
        if (toggle) toggleFunc(e, toggle);
        if (toggleTable) toggleTableFunc(e, toggleTable);
        if (toggleChangeItem) {
            toggleChangeItemFunc(e, toggleChangeItem);
            if ($(toggleChangeItem).parents('.bet-content-filters').length) {
                sortInventoryByRarityAndGame(inventory);
            }
        }
        if (tab) tabFunc(e, tab);
        if (tabItem) tabItemFunc(e, tabItem);
        if (betItem) betItemFunc(betItem);
        if (close) closeFunc(e, close);
        if (btnMore) btnMoreFunc(e, btnMore);
        if (alert) alertFunc(e, alert);
        if (linkScroll) linkScrollFunc(e, linkScroll);
        if (jsChart) charts(e, jsChart);
        if (jsGame) jsGameFunc(e, jsGame);
        if (jsMoreBet) moreBetFunc(e, jsMoreBet);
        if (jsCopy) copyFunc(e, jsCopy);
        if (tabsNavShow) tabsNavShowFunc(tabsNavShow);
        if (sortItem) {
            sortFunc(e, sortItem);
            if ($(sortItem).parents('.js__sort_price').length) {
                sortInventoryByPrice(inventory);
            }
            if ($(sortItem).parents('.js__sort_discount').length) {
                sortInventoryByDiscount(inventory);
            }
        }
        else if (false && sort) {
            sortFunc(e, sort);
            if ($(sort).parents('.bet-content-filters').length) {
                sortInventoryByPrice($(sort).parents('.bet-content-filters').parent().find('.inventory').first());
            }
        }
        if (die) dieFunc(die);
        if (jsBar) barMenu(jsBar);
        if (btnLoad) btnFuncLoad(btnLoad);
        // if (modal) {
        //     if (!modal.classList.contains('notification') && !target.closest('.modal-body') && !target.closest('.btn-more') &&  !target.classList.contains('js-chart') || target.closest('.form-auth-switch__item')) {
        //         console.log('close is here');
        //         document.body.classList.remove('over-y-hide');
        //         modal.classList.remove('show');
        //         setTimeout(() => modal.classList.remove('open'), 300);
        //     }
        // }
        if(jsPay) payFunc(jsPay);
        if (jsCopyElement) jsCopyFunc(jsCopyElement, e);
        if (allinBet) allinCancelFunc(allinBet);
        if (cancelBet) allinCancelFunc(cancelBet);
    });

    document.addEventListener('input', (e) => {
        const target = e.target;
        const range = target.closest("input[type='range']");
        const rangeInput = target.closest('.input-range-value');
        const paymentInput = target.closest(".payment-input");
        const inputNecessarily = target.closest('.input-necessarily');
        const searchItemName = target.closest('.js__sort_search input');


        if (range) rangeFunc(range);
        if (rangeInput) rangeInputFunc(rangeInput);
        if (paymentInput) paymentFunc(paymentInput);
        if (inputNecessarily) form(inputNecessarily);
        if (searchItemName) {
            sortInventoryByName($(searchItemName).parents('.bet-content-filters').parent().find('.inventory').first());
        }
    });

    document.addEventListener('mousedown', (e) => {
        if (e.button !== 0) return; //only left button
        const target = e.target;
        const modal = document.querySelector('.modal.open');

        if (modal) {
            if (!modal.classList.contains('notification') && !target.closest('.modal-body') && !target.closest('.btn-more') &&  !target.classList.contains('js-chart') || target.closest('.form-auth-switch__item')) {
                // console.log('close is here');
                document.body.classList.remove('over-y-hide');
                modal.classList.remove('show');
                setTimeout(() => modal.classList.remove('open'), 300);
            }
        }
    });

    document.addEventListener("keypress", function (e) {
        const target = e.target;
        const number = target.closest("input[type='number']");
        if (number) {
            if (e.key === '+' || e.key === '-' || e.key === 'e') e.preventDefault();
        }
    });

    document.addEventListener('mouseover', (e) => {
        const target = e.target;
        const betItem = target.closest(".bet-item");

        if (betItem) betItemHoverFunc(betItem, 'mouseover');
    });

    document.addEventListener('mouseenter', (e) => {
        const target = e.target;
        const item = target.closest?.(".pay__item");
        // const question = target.closest?.(".question");
        
        if (item) onItemHover(e, item);
        if (target?.classList?.contains("question")) onQuestionHover(e, target);
    }, {capture: true});

    document.addEventListener('mouseout', (e) => {
        const target = e.target;
        const betItem = target.closest(".bet-item");

        if (betItem) betItemHoverFunc(betItem, 'mouseout');
    });

    window.addEventListener('load', () => {
        updateButtonVisibility();
        lastBetScrollChange();
        initStreamPlayers();
    });

    window.addEventListener('resize', () => {
        updateButtonVisibility();
    });

    const scrollableLast = document.querySelector('.last');

    if (scrollableLast) {
        scrollableLast.addEventListener('scroll', function () {
            const scrollTop = scrollableLast.scrollTop;

            if (scrollTop > 0) scrollableLast.closest('.block__item-wrap').classList.add('line');
            else scrollableLast.closest('.block__item-wrap').classList.remove('line');
        });
    }
};