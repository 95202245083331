import { updateButtonVisibility } from "../bet/updateButtonVisibility.js";
import { toggle } from "../toggle/toggle.js";
import { toSVG } from "../helpers/toSVG.js";

export const alertFunc = (e, item) => {

    if (e) e.preventDefault();

    const title = item.getAttribute('data-title');
    const alert = document.querySelector(`.item-close[data-alert="${title}"]`);

    if (!alert) return false;
    if (alert.querySelector('.show-more-block')) setTimeout(() => updateButtonVisibility(), 500);

    if (alert.classList.contains('modal')) {
        if (!alert.classList.contains('notification')) document.body.classList.add('over-y-hide');
        alert.classList.add('open');
        setTimeout(() => alert.classList.add('show'), 300);
        toggle();
    } else {
        alert.classList.remove('hidden');
        setTimeout(() => alert.classList.remove('height'), 250);
    }
    toSVG();
};

export const alertFuncPreloadInit = () => {

    const alerts = document.querySelectorAll('.form-preload-js');
    if (alerts.length) {
        alerts.forEach(alert => {
            if (alert.querySelector('.show-more-block')) setTimeout(() => updateButtonVisibility(), 500);

            if (alert.classList.contains('modal')) {
                if (!alert.classList.contains('notification')) document.body.classList.add('over-y-hide');
                alert.classList.add('open');
                setTimeout(() => alert.classList.add('show'), 300);
                toggle();
            } else {
                alert.classList.remove('hidden');
                setTimeout(() => alert.classList.remove('height'), 250);
            }
        });
    }
};