import Cookies from 'js-cookie'

export const initFilters = () => {
    $('.filter-games-checkbox>input').on('click', function () {
        var $game = $(this).attr('id');
        if ($(this).prop('checked')) {
            Cookies.set($game, true, { expires: 365 });
            $(`div.games[data-game-text-id=${$game}]`).show();
        }
        else {
            Cookies.set($game, false, { expires: 365 });
            $(`div.games[data-game-text-id=${$game}]`).hide();
        }
        showHidePlaceholder();
    });

    $('.show_all_matches').on('click', function () {
        $('.filter-games-checkbox>input:not(:checked)').click();
    });
}

export const showHidePlaceholder = () => {
    //для каждого блока с играми
    $('.block__item-wrapper').each(function () {
        //если в блоке есть игры но они невидимые то показываем плейсхолдер
        if ($(this).find('div.games').length > 0) {
            if ($(this).find('div.games:visible').length == 0) {
                $(this).find('.empty_placeholder').show();
            }
            else {
                $(this).find('.empty_placeholder').hide();
            }
        }
    });
    $('.show_all_matches').each(function () {
        let $wrapper = $(this).closest('.block__item-wrapper');
        if ($wrapper.find('div.games').length > 0) {
            if ($wrapper.find('div.games:hidden').length > 0) {
                $(this).find('.hidden_matches_count').text($wrapper.find('div.games:hidden').length);
                $(this).show();
            }
            else {
                $(this).hide();
            }
        }

    });
}