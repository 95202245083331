import {new_swiper} from "./sliders.js";

export const swiperTabsWrap = () => {
    const swiper = document.querySelector('.swiper-tabs-wrap');

    if (!swiper) return false;

    const swiperTabsList = document.querySelector('.swiper-tabs-list');
    const swiperInstance = swiperTabsList.swiper;
    
    const s = new Swiper(swiper, {
        effect: 'fade',
        autoHeight: true,
        fadeEffect: {
            crossFade: true
        },
        thumbs: {
            swiper: swiperInstance,
        },
        breakpoints: {
            0: {
                allowTouchMove: true,
            },
            1200: {
                allowTouchMove: false,
            }
        },
        on: {
            // slideChange: function (item) {
            //     const currentIndex = item.activeIndex;
            //     const slide = item.el.querySelectorAll('.swiper-slide:not(.tabs__item)');

            //     if (slide.length) {
            //         const table = slide[currentIndex].querySelector('.table');

            //         if (table) {
            //             const swiperInstance = swiper.swiper;
            //             swiperInstance.allowTouchMove = false;
            //         }
            //     }
            // }
        },
        noSwipingSelector: '.games-top-tabs.bookmark, .bet__range-input, table.table.dataTable, .section__table table',
        // initialSlide: 0,
        shortSwipes: false,
        longSwipesRatio: 0.15,
        longSwipesMs: 50,
        a11y: false,
    });

    // let initTab = 0;
    // s.slides.forEach(function (slide, index) {
    //     if ('#'+slide.getAttribute('anchor') == location.hash || slide.getAttribute('href') == location.hash || slide.classList.contains('prechoosed')) {
    //         s.slideTo(index, 400);
    //         initTab = index;
    //     }
    // });
    // console.log('swiperTabsWrap', initTab, swiper, s.slides, swiperTabsList, swiperTabsList.slides);

    new_swiper(s);
    return s;
};