import {new_swiper} from "./sliders.js";
//import Swiper from 'swiper/swiper.js';

export const swiperScroll = () => {
    const swiper = document.querySelector('.swiper-scroll .swiper');

    if (!swiper) return false;

    const s = new Swiper(swiper, {
        direction: "vertical",
        slidesPerView: "auto",
        freeMode: true,
        scrollbar: {
            el: ".swiper-scroll .swiper-scrollbar",
            draggable: true,
        },
        mousewheel: true,
    });

    new_swiper(s);
    return s;
}