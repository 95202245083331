export const updateButtonVisibility = () => {
    const gridContainer = document.querySelectorAll('.show-more-block');

    if (gridContainer.length) {
        gridContainer.forEach(item => {
            const parent = item.closest('.show-more-wrap');
            const content = parent.querySelector('.show-more-content');
            const btn = parent.querySelector('.btn-more');
            const computedStyle = window.getComputedStyle(content);
            const gridTemplateRows = computedStyle.getPropertyValue('grid-template-rows');
            const rowsArray = gridTemplateRows.split(' ');

            if (rowsArray.length > 1) {
                if (btn) {
                    parent.classList.add('shadow');
                    btn.classList.remove('d-none');
                }
            } else {
                if (btn) {
                    parent.classList.remove('shadow');
                    btn.classList.add('d-none');
                }
            }
        });
    }
};