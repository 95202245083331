export const onQuestionHover = function onMouseenter(e, question) {
    let hint = question.querySelector('.hint');
    hint.classList.remove('left');
    hint.classList.remove('center');

    let scrollHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
      );
    let scrollWidth = Math.max(
        document.body.scrollWidth, document.documentElement.scrollWidth,
        document.body.offsetWidth, document.documentElement.offsetWidth,
        document.body.clientWidth, document.documentElement.clientWidth
      );


    let rect = hint.getBoundingClientRect();
    console.log('fixing hint', hint, rect, scrollHeight, scrollWidth);

    if (rect.right <= scrollWidth) return;
    hint.classList.add('left');
    rect = hint.getBoundingClientRect();

    // if (rect.right >= 0) return;
    // hint.classList.remove('left');
    // hint.classList.add('center');
}