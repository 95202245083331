/**
 * Translations object containing messages for different languages
 * @type {Object.<string, Object.<string, string>>}
 */
const translations = {
  en: {
    "Error sending request": "Error sending request",
    "Error": "Error",
    "Your progress": "Your progress",
    // Add more English translations here
  },
  ru: {
    "Error sending request": "Ошибка отправки запроса",
    "Error": "Ошибка",
    "Your progress": "Ваш прогресс",
    // Add more Russian translations here
  },
  uk: {
    "Error sending request": "Помилка надсилання запиту",
    "Error": "Помилка",
    "Your progress": "Ваш прогрес",
    // Add more Ukrainian translations here
  },
  es: {
    "Error sending request": "Error al enviar la solicitud",
    "Error": "Error",
    "Your progress": "Tu progreso",
    // Add more Spanish translations here
  },
};

/**
 * Get the current language from the html's lang attribute
 * @returns {string} The current language code (ISO 639-1)
 */
export function getCurrentLanguage() {
  const fullLocale = document.documentElement.lang || 'en';
  return fullLocale.split('-')[0].toLowerCase();
}

/**
 * Translate a message to the current language
 * @param {string} message - The message to translate
 * @returns {string} The translated message or the original message if no translation is found
 */
export function translate(message) {
  const lang = getCurrentLanguage();
  
  if (!Object.prototype.hasOwnProperty.call(translations, lang)) {
    console.warn(`Translation not available for language: ${lang}`);
    return translations['en'][message] || message;
  }
  
  return translations[lang][message] || translations['en'][message] || message;
}

/**
 * Set the current language
 * @param {string} lang - The ISO 639-1 language code (e.g., 'en', 'es')
 * @throws {Error} If the provided language code is invalid
 */
export function setLanguage(lang) {
  if (typeof lang !== 'string' || !/^[a-z]{2}$/.test(lang)) {
    throw new Error('Invalid language code. Please use ISO 639-1 format (e.g., en, es)');
  }
  document.documentElement.lang = lang;
}