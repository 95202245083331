export const rangeFunc = (item, sync = true) => {
    // Check for number and convert or reset
    item.value = isNaN(item.value) ? 0 : Number(item.value);
    let itemValue = Number(item.value);

    // Find the closest parent with class '.bet__range'
    const parent = item.closest('.bet__range');
    if (!parent) return;

    // Query for necessary elements within the parent
    const inputRangeValue = parent.querySelector('.input-range-value');
    const rangeInput = parent.querySelector('.bet__range-input');
    if (!inputRangeValue || !rangeInput) return;

    // Retrieve and parse attribute values or use defaults
    const maxAttr = item.getAttribute('max');
    const minAttr = item.getAttribute('min');
    const max = maxAttr !== null ? Number(maxAttr) : Number.MAX_SAFE_INTEGER;
    const min = minAttr !== null ? Number(minAttr) : 0;

    // Synchronize values if applicable
    if (sync) inputRangeValue.value = itemValue;

    // Set data attributes based on the value range
    rangeInput.setAttribute('data-range', 
        itemValue === min ? 'start' :
        itemValue === max ? 'end' : 'center');

    // Trigger change event on input elements of type 'range' using jQuery
    $(parent).find('input.input-range').change();
    };