export const lastBetScrollChange = () => {
    const element = document.querySelector('.last');

    if (!element) return false;

    const item = element.closest('.no-line');

    if (item) {
        if (element.scrollHeight > element.clientHeight) {
            item.classList.remove('no-line');
            item.classList.add('scroll');
        } else {
            item.classList.add('no-line');
            item.classList.remove('scroll');
        }
    }
};